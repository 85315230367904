import React, {useState, useCallback} from 'react';
import { addDays, format } from 'date-fns'
import {
  FormLayout,
  Checkbox,
  TextField,
  Layout,
  DatePicker,
  Popover,
  Button,
  Icon,
  Text,
} from '@shopify/polaris';
import update from "immutability-helper";
import {
  CalendarIcon
} from '@shopify/polaris-icons';
import FieldTitle from '../../../../../components/fieldTitle';
import CardDivider from '../../../../../components/cardDivider';
const AdvancedTab = (props) => {
  const {state, setState} = props;
  const [datePickerPopoverVisible, setDatePickerPopoverVisible] = useState(false)
  const date = new Date()
    const [{month, year}, setDate] = useState({month: date.getMonth(), year: date.getFullYear()});
    const [selectedDates, setSelectedDates] = useState({
        start: date,
        end: addDays(date, 30),
    });

  const handleChange = useCallback(
    (newChecked) => setState(update(state, {swapPrice: {$set: newChecked}})),
    [],
  );
  const handlePricingDecimals = useCallback(
    (value) => setState(update(state, {pricingDecimals: {$set: value}})),
    [],
  );

  const handleOfferRunDateChange = () => {
    setState(update(state, { offerStarting: { $set: selectedDates.start }, offerEnding: { $set: selectedDates.end }}))
  }

  const handleMonthChange = useCallback(
    (month, year) => setDate({month, year}),
    [],
  );

  const datePickerActivatorButton = (
      <Button onClick={() => setDatePickerPopoverVisible(true)}>
        <div style={{display:'flex', alignItems: 'center',justifyContent: 'center', gap: '5px'}}>
        <Icon
          source={CalendarIcon}
          tone="base" style={{display:'inline-block'}}></Icon>
          { state.offerStarting !== "" && state.offerEnding !== "" ?
            state.offerStarting.seconds &&  state.offerEnding.seconds ?
              <span>{format(new Date(state.offerStarting.seconds * 1000), "yyyy-MM-dd")} - {format(new Date(state.offerEnding.seconds * 1000), "yyyy-MM-dd")}</span>
              :
              <span>{format(new Date(state.offerStarting), "yyyy-MM-dd")} - {format(new Date(state.offerEnding), "yyyy-MM-dd")}</span>
            :
            <span>Today - Now</span>
            }
        </div>
      </Button>
    );

  return (
    <FormLayout>
      <div>
      <FieldTitle title="Change which amount is crossed out"/>
      <Checkbox
        label="Swap original price and compare at price"
        checked={state.swapPrice}
        onChange={handleChange}
      />
      </div>
      <TextField
        label={<FieldTitle title="Pricing decimals"/>}
        min={0}
        value={state.pricingDecimals}
        onChange={(value) => handlePricingDecimals(value)}
        autoComplete="off"
        placeholder="2"
        type="number"
      />
      <div>
        <FieldTitle title="Scheduling"/>
        <Popover
          active={datePickerPopoverVisible}
          activator={datePickerActivatorButton}
          onClose={()=>setDatePickerPopoverVisible(false)}
          fluidContent
          fullHeight
        >
          <div style={{maxWidth: '600px', padding: '20px'}}>
          <Layout>
            <Layout.Section>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  type="string"
                  label="Starting"
                  onChange={() => {}}
                  autoComplete="off"
                  value={format(selectedDates.start, "yyyy-MM-dd")}
                />
                <TextField
                  type="string"
                  label="Ending"
                  onChange={() => {}}
                  autoComplete="off"
                  value={format(selectedDates.end, "yyyy-MM-dd")}
                />
              </FormLayout.Group>
            </FormLayout>
            </Layout.Section>
            <Layout.Section>
              <DatePicker
                month={month}
                year={year}
                onChange={setSelectedDates}
                onMonthChange={handleMonthChange}
                selected={selectedDates}
                multiMonth
                allowRange
              />
            </Layout.Section>
            <Layout.Section>
              <div style={{display:'flex', justifyContent:'space-between'}}>
                <Button onClick={()=>setDatePickerPopoverVisible(false)}>Cancel</Button>
                <Button variant="primary" onClick={()=>{
                  handleOfferRunDateChange();
                  setDatePickerPopoverVisible(false)
                  }}>Apply</Button>
              </div>
            </Layout.Section>
          </Layout>
          </div>
        </Popover>
        <div style={{paddingTop: '10px'}}>
          <Text as="span" color="subdued">Schedule an offer for the future.</Text>
        </div>
      </div>
        
    </FormLayout>
  );
};

export default AdvancedTab;